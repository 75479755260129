/**
 * @generated SignedSource<<5d5a83a310355c6988506ad24ecd42a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ShareContentUsageEmailModalBody_notificationAudienceQuery$variables = {
  organizationId: string;
  productId: string;
};
export type ShareContentUsageEmailModalBody_notificationAudienceQuery$data = {
  readonly notificationAudience: {
    readonly totalCount: number;
  } | null;
};
export type ShareContentUsageEmailModalBody_notificationAudienceQuery = {
  response: ShareContentUsageEmailModalBody_notificationAudienceQuery$data;
  variables: ShareContentUsageEmailModalBody_notificationAudienceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "channel",
        "value": "email"
      },
      {
        "kind": "Literal",
        "name": "notificationKindSlug",
        "value": "curriculum_item_share"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "productId",
        "variableName": "productId"
      },
      {
        "kind": "Literal",
        "name": "topic",
        "value": "curriculum-item-share"
      }
    ],
    "concreteType": "UserNodeConnection",
    "kind": "LinkedField",
    "name": "notificationAudience",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareContentUsageEmailModalBody_notificationAudienceQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShareContentUsageEmailModalBody_notificationAudienceQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd1e8300644725a0ada4663bcae7074e",
    "id": null,
    "metadata": {},
    "name": "ShareContentUsageEmailModalBody_notificationAudienceQuery",
    "operationKind": "query",
    "text": "query ShareContentUsageEmailModalBody_notificationAudienceQuery(\n  $organizationId: ID!\n  $productId: ID!\n) {\n  notificationAudience(organizationId: $organizationId, productId: $productId, topic: \"curriculum-item-share\", notificationKindSlug: curriculum_item_share, channel: email) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fc2609a04aff595e34cb53c6e97f5ec";

export default node;

/**
 * @generated SignedSource<<14c2dce44a0d7d8b5eace9f869dc98a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShareContentUsageUtils_useShareUrlFragment$data = {
  readonly entity: ContentUsageEntity;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useContentUsagePathContentUsageFragment">;
  readonly " $fragmentType": "ShareContentUsageUtils_useShareUrlFragment";
};
export type ShareContentUsageUtils_useShareUrlFragment$key = {
  readonly " $data"?: ShareContentUsageUtils_useShareUrlFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShareContentUsageUtils_useShareUrlFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShareContentUsageUtils_useShareUrlFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useContentUsagePathContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "2dee609afaa2028af9024b5dd99cdd45";

export default node;

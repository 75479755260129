/**
 * @generated SignedSource<<c726b21cd3695ccba386e1001476e03b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "events" | "group_directory" | "link" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageUtils_UseParentEntityContentUsageFragment$data = {
  readonly entity: ContentUsageEntity;
  readonly id: string;
  readonly module: {
    readonly isCollectionModule: boolean;
    readonly isCurriculumModule: boolean;
  } | null;
  readonly productApp: {
    readonly kind: ProductAppKind;
  } | null;
  readonly " $fragmentType": "ContentUsageUtils_UseParentEntityContentUsageFragment";
};
export type ContentUsageUtils_UseParentEntityContentUsageFragment$key = {
  readonly " $data"?: ContentUsageUtils_UseParentEntityContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_UseParentEntityContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageUtils_UseParentEntityContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurriculumModule",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCollectionModule",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "productApp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "8448ed4da1726a060e5f0d76f25405a1";

export default node;

/**
 * @generated SignedSource<<a8ca7918bb1e8a1a92c19cbb069daece>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "events" | "group_directory" | "link" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageUtils_useContentUsagePathContentUsageFragment$data = {
  readonly entity: ContentUsageEntity;
  readonly entityId: string;
  readonly id: string;
  readonly module: {
    readonly usages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entity: ContentUsageEntity;
          readonly entityId: string;
          readonly id: string;
        };
      }>;
    };
  } | null;
  readonly product: {
    readonly slug: string;
  } | null;
  readonly productApp: {
    readonly kind: ProductAppKind;
  } | null;
  readonly " $fragmentType": "ContentUsageUtils_useContentUsagePathContentUsageFragment";
};
export type ContentUsageUtils_useContentUsagePathContentUsageFragment$key = {
  readonly " $data"?: ContentUsageUtils_useContentUsagePathContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useContentUsagePathContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entity",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageUtils_useContentUsagePathContentUsageFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "productApp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "a9e75ccdffa9928bb644b7c5cdae463e";

export default node;

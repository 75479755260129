/**
 * @generated SignedSource<<95dd528ec2c10b7417430f91701f7614>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditSlackInviteContentButtonModal_ContentUsageFragment$data = {
  readonly content: {
    readonly id: string;
    readonly label: string;
    readonly name: string | null;
    readonly thumbnailUrl: string | null;
    readonly type: ContentType;
  };
  readonly entity: ContentUsageEntity;
  readonly id: string;
  readonly " $fragmentType": "EditSlackInviteContentButtonModal_ContentUsageFragment";
};
export type EditSlackInviteContentButtonModal_ContentUsageFragment$key = {
  readonly " $data"?: EditSlackInviteContentButtonModal_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditSlackInviteContentButtonModal_ContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditSlackInviteContentButtonModal_ContentUsageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "cf5973413740161cd1bd771508d45c3c";

export default node;

/**
 * @generated SignedSource<<b554677bd004df410503d3be024a51c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModuleUtils_RefreshCurriculumFragment$data = {
  readonly id: string;
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly ordering: number | null;
        readonly " $fragmentSpreads": FragmentRefs<"ContentModuleListItemFragment">;
      };
    }>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "ContentModuleUtils_RefreshCurriculumFragment";
};
export type ContentModuleUtils_RefreshCurriculumFragment$key = {
  readonly " $data"?: ContentModuleUtils_RefreshCurriculumFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleUtils_RefreshCurriculumFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contentLabelIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleUtils_RefreshCurriculumFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordering",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "contentLabelIds",
                      "variableName": "contentLabelIds"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "ContentModuleListItemFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Curriculum",
  "abstractKey": null
};
})();

(node as any).hash = "a335a99ce96ec6176ba6c2978a8baf73";

export default node;

/**
 * @generated SignedSource<<f10ccb83dea080c84e1c62762a979a74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "add_events_to_calendar" | "calendar_connection" | "community_agreement" | "complete_profile" | "connect_slack" | "custom_profile_field" | "default_profile_fields" | "download_mobile_app" | "onboarding_complete" | "onboarding_module" | "onboarding_welcome" | "slack_connection" | "slack_invite" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageUtils_useNavigateToNextContentUsageFragment$data = {
  readonly id: string;
  readonly module: {
    readonly children: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly content: {
            readonly id: string;
            readonly name: string | null;
            readonly systemTaskKind: ContentSystemTaskKind | null;
            readonly type: ContentType;
            readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnailWithDetails_ContentFragment">;
          };
          readonly id: string;
        };
      }>;
    } | null;
  } | null;
  readonly " $fragmentType": "ContentUsageUtils_useNavigateToNextContentUsageFragment";
};
export type ContentUsageUtils_useNavigateToNextContentUsageFragment$key = {
  readonly " $data"?: ContentUsageUtils_useNavigateToNextContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useNavigateToNextContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageUtils_useNavigateToNextContentUsageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "children",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Content",
                      "kind": "LinkedField",
                      "name": "content",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "systemTaskKind",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ContentThumbnailWithDetails_ContentFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "862a908c105dc6d479e46684b118cad2";

export default node;

/**
 * @generated SignedSource<<eabea63bb40cc9cec208083b66ba20fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentNotificationEmailCtaButton = "disabled" | "enabled" | "%future added value";
export type ContentSystemTaskKind = "add_events_to_calendar" | "calendar_connection" | "community_agreement" | "complete_profile" | "connect_slack" | "custom_profile_field" | "default_profile_fields" | "download_mobile_app" | "onboarding_complete" | "onboarding_module" | "onboarding_welcome" | "slack_connection" | "slack_invite" | "%future added value";
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
export type DuplicateContentInput = {
  content?: CreateContentInput | null;
  contentId: string;
};
export type CreateContentInput = {
  attachments?: ReadonlyArray<ContentAttachmentInput> | null;
  contentLabelId?: string | null;
  coverAudio?: string | null;
  coverPhoto?: string | null;
  coverPhotoAssetId?: string | null;
  coverVideo?: string | null;
  coverVideoAssetId?: string | null;
  coverVideoDurationSeconds?: number | null;
  createdById?: string | null;
  description?: string | null;
  id?: string | null;
  isAiGenerated?: boolean | null;
  name?: string | null;
  notificationEmailCtaButton?: ContentNotificationEmailCtaButton | null;
  notificationEmailSubject?: string | null;
  releaseDatetime?: string | null;
  richEditorDescriptionContent?: string | null;
  richEditorNotificationEmailBodyContent?: string | null;
  sendNotificationEmail?: boolean | null;
  showComments?: boolean | null;
  showCoverPlayIcon?: boolean | null;
  submissionLink?: string | null;
  systemTaskKind?: ContentSystemTaskKind | null;
  thumbnailAssetId?: string | null;
  thumbnailUrl?: string | null;
  webFormRevision?: CreateWebFormRevisionInput | null;
};
export type ContentAttachmentInput = {
  id?: string | null;
  mediaAssetId?: string | null;
  mediaUrl?: string | null;
  name?: string | null;
};
export type CreateWebFormRevisionInput = {
  contentUsageId?: string | null;
  questions: ReadonlyArray<WebFormQuestionInput>;
  webFormId: string;
};
export type WebFormQuestionInput = {
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  richEditorBody: string;
  type: WebFormQuestionType;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type AddContentUsageContentListItem_duplicateContentMutation$variables = {
  input: DuplicateContentInput;
};
export type AddContentUsageContentListItem_duplicateContentMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly organization: {
        readonly adminLibraryContents: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly " $fragmentSpreads": FragmentRefs<"AddContentUsageContentListItemFragment">;
            };
          }>;
        };
      };
    } | null;
  };
};
export type AddContentUsageContentListItem_duplicateContentMutation = {
  response: AddContentUsageContentListItem_duplicateContentMutation$data;
  variables: AddContentUsageContentListItem_duplicateContentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddContentUsageContentListItem_duplicateContentMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DuplicateContentResponse",
        "kind": "LinkedField",
        "name": "duplicateContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Content",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentNodeConnection",
                    "kind": "LinkedField",
                    "name": "adminLibraryContents",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "AddContentUsageContentListItemFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddContentUsageContentListItem_duplicateContentMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DuplicateContentResponse",
        "kind": "LinkedField",
        "name": "duplicateContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Content",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentNodeConnection",
                    "kind": "LinkedField",
                    "name": "adminLibraryContents",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isAiGenerated",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "creationDatetime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "coverVideo",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnailUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "showCoverPlayIcon",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c7fc7c783ca865645758c558bf0c9fb",
    "id": null,
    "metadata": {},
    "name": "AddContentUsageContentListItem_duplicateContentMutation",
    "operationKind": "mutation",
    "text": "mutation AddContentUsageContentListItem_duplicateContentMutation(\n  $input: DuplicateContentInput!\n) {\n  response: duplicateContent(input: $input) {\n    node {\n      id\n      organization {\n        adminLibraryContents {\n          edges {\n            node {\n              id\n              ...AddContentUsageContentListItemFragment\n            }\n          }\n        }\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment AddContentUsageContentListItemFragment on Content {\n  id\n  type\n  name\n  isAiGenerated\n  ...ContentThumbnailWithDetails_ContentFragment\n}\n\nfragment ContentThumbnailWithDetails_ContentFragment on Content {\n  id\n  type\n  label\n  name\n  creationDatetime\n  ...ContentThumbnail_ContentFragment\n}\n\nfragment ContentThumbnail_ContentFragment on Content {\n  id\n  type\n  coverVideo\n  thumbnailUrl\n  showCoverPlayIcon\n}\n"
  }
};
})();

(node as any).hash = "6e9bc4042317cd5ba72ae5d0611bd93c";

export default node;

/**
 * @generated SignedSource<<60cb2efedf13dab1db486b45204cb772>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateCurriculumModuleItemContentUsageButtonFragment$data = {
  readonly content: {
    readonly createCurriculumModuleItemContentUsageButtonChildren: {
      readonly totalCount: number;
    } | null;
    readonly id: string;
  };
  readonly entity: ContentUsageEntity;
  readonly entityId: string;
  readonly id: string;
  readonly productId: string | null;
  readonly " $fragmentType": "CreateCurriculumModuleItemContentUsageButtonFragment";
};
export type CreateCurriculumModuleItemContentUsageButtonFragment$key = {
  readonly " $data"?: CreateCurriculumModuleItemContentUsageButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateCurriculumModuleItemContentUsageButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateCurriculumModuleItemContentUsageButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "createCurriculumModuleItemContentUsageButtonChildren",
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "children",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "0005da21b725e450ad1130a5e0421313";

export default node;

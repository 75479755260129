/**
 * @generated SignedSource<<9d36ec480c049e73a8fd729fcca35632>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModuleUtils_useOnCurriculumModuleCompletedFragment$data = {
  readonly id: string;
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly viewerHasCompleted: boolean;
      };
    }>;
  };
  readonly " $fragmentType": "ContentModuleUtils_useOnCurriculumModuleCompletedFragment";
};
export type ContentModuleUtils_useOnCurriculumModuleCompletedFragment$key = {
  readonly " $data"?: ContentModuleUtils_useOnCurriculumModuleCompletedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleUtils_useOnCurriculumModuleCompletedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleUtils_useOnCurriculumModuleCompletedFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "viewerHasCompleted",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Curriculum",
  "abstractKey": null
};
})();

(node as any).hash = "58bb45195b939790a09f257fee150214";

export default node;

/**
 * @generated SignedSource<<832648be59b08e88e161eb7e19568754>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventVisibility = "cohort" | "member_group" | "public" | "%future added value";
export type OccurrenceStatus = "draft" | "published" | "%future added value";
export type ContentUsageFormEventsFieldQuery$variables = {
  organizationId: string;
  productId: string;
};
export type ContentUsageFormEventsFieldQuery$data = {
  readonly organization: {
    readonly occurrences?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly content: {
            readonly name: string | null;
          };
          readonly datetimeRange: ReadonlyArray<string>;
          readonly id: string;
          readonly status: OccurrenceStatus;
          readonly visibility: EventVisibility;
          readonly " $fragmentSpreads": FragmentRefs<"OccurrenceNameAndTimeFragment">;
        };
      }>;
    };
  } | null;
};
export type ContentUsageFormEventsFieldQuery = {
  response: ContentUsageFormEventsFieldQuery$data;
  variables: ContentUsageFormEventsFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "includedProductIds.0",
        "variableName": "productId"
      }
    ],
    "kind": "ListValue",
    "name": "includedProductIds"
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": "published"
  },
  {
    "kind": "Literal",
    "name": "visibility",
    "value": [
      "cohort",
      "public",
      "member_group"
    ]
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datetimeRange",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentUsageFormEventsFieldQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "occurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Occurrence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "content",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "OccurrenceNameAndTimeFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContentUsageFormEventsFieldQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "occurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Occurrence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "content",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Event",
                            "kind": "LinkedField",
                            "name": "event",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isRecurring",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bcef3721d5220faf1143a56b21eee8a7",
    "id": null,
    "metadata": {},
    "name": "ContentUsageFormEventsFieldQuery",
    "operationKind": "query",
    "text": "query ContentUsageFormEventsFieldQuery(\n  $productId: ID!\n  $organizationId: ID!\n) {\n  organization: node(id: $organizationId) {\n    __typename\n    ... on Organization {\n      occurrences(status: published, visibility: [cohort, public, member_group], includedProductIds: [$productId]) {\n        edges {\n          node {\n            id\n            status\n            datetimeRange\n            visibility\n            content {\n              name\n              id\n            }\n            ...OccurrenceNameAndTimeFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment OccurrenceNameAndTimeFragment on Occurrence {\n  id\n  name\n  datetimeRange\n  event {\n    isRecurring\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1417aca37101fa184de92af189174674";

export default node;

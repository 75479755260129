/**
 * @generated SignedSource<<259776655950c223f19fcb5801698d74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddContentUsageContentListItemFragment$data = {
  readonly id: string;
  readonly isAiGenerated: boolean | null;
  readonly name: string | null;
  readonly type: ContentType;
  readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnailWithDetails_ContentFragment">;
  readonly " $fragmentType": "AddContentUsageContentListItemFragment";
};
export type AddContentUsageContentListItemFragment$key = {
  readonly " $data"?: AddContentUsageContentListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddContentUsageContentListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddContentUsageContentListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAiGenerated",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentThumbnailWithDetails_ContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "8fd4aa7c69d85a34aac2702b31a18a08";

export default node;

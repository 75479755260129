/**
 * @generated SignedSource<<6e55a64ace9b4d434460b104c42151c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateCollectionModuleItemContentUsageButtonFragment$data = {
  readonly content: {
    readonly id: string;
  };
  readonly id: string;
  readonly productId: string | null;
  readonly " $fragmentType": "CreateCollectionModuleItemContentUsageButtonFragment";
};
export type CreateCollectionModuleItemContentUsageButtonFragment$key = {
  readonly " $data"?: CreateCollectionModuleItemContentUsageButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateCollectionModuleItemContentUsageButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateCollectionModuleItemContentUsageButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "fb701fc67da8c4f12855e9545f359948";

export default node;

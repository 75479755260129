/**
 * @generated SignedSource<<050008716775853a415d6797227075cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "events" | "group_directory" | "link" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageInstanceItem_ContentUsageFragment$data = {
  readonly entity: ContentUsageEntity;
  readonly id: string;
  readonly module: {
    readonly name: string | null;
    readonly usages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly collection: {
            readonly app: {
              readonly badge: {
                readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
              };
              readonly customAppTitle: string | null;
            };
          } | null;
          readonly id: string;
          readonly isDefaultCollectionModule: boolean;
        };
      }>;
    };
  } | null;
  readonly product: {
    readonly badge: {
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    } | null;
    readonly name: string;
    readonly registrationAvailability: ProductRegistrationAvailability;
  } | null;
  readonly productApp: {
    readonly badge: {
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    };
    readonly customAppTitle: string | null;
    readonly kind: ProductAppKind;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useContentUsagePathContentUsageFragment">;
  readonly " $fragmentType": "ContentUsageInstanceItem_ContentUsageFragment";
};
export type ContentUsageInstanceItem_ContentUsageFragment$key = {
  readonly " $data"?: ContentUsageInstanceItem_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageInstanceItem_ContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badge",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BadgeFragment"
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppTitle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageInstanceItem_ContentUsageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registrationAvailability",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "productApp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        (v3/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isDefaultCollectionModule",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Collection",
                      "kind": "LinkedField",
                      "name": "collection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductApp",
                          "kind": "LinkedField",
                          "name": "app",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/),
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useContentUsagePathContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "f898591c83debc2e76499b77d2fbc368";

export default node;
